<template>
  <div online-coach>
    <div class="discord-wrap">
      <DiscordButton />
    </div>
    <CoachList />
  </div>
</template>

<script>
import CoachList from '@/views/components/coaching/coachList/CoachList.vue';
import DiscordButton from '@/views/components/coaching/DiscordButton.vue';

export default {
  name: 'OnlineCoach.vue',
  components: { DiscordButton, CoachList },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[online-coach] {.p(32, 20);
  [coach-list-view] {.w(100%);
    .top-holder {
      .filter-layer {
        .w(100%); .l(0);
      }
    }
  }
}
</style>
